import { ReactNode } from 'react'
import {
  AssignmentInd,
  Business as BusinessIcon,
  Chat as ChatIcon,
  Dashboard as DashboardIcon,
  Equalizer,
  Healing as HealingIcon,
  HowToReg as HowToRegIcon,
  ListAlt as ListIcon,
  PersonPinCircle as PersonPinCircleIcon,
  RecentActors as RecentActorsIcon,
  SettingsApplications as SettingsApplicationsIcon,
  TransferWithinAStation as TransferWithinAStationIcon,
  WatchLaterOutlined,
  PersonAddAlt as PersonAddAltIcon,
  PlaylistAddCheck,
} from '@mui/icons-material'
import { menu } from '../../constants/menu'
import { menuDetail } from '../../constants/menuDetail'
import PlusLabel from '../../components/Label/PlusLabel'

export type MenuGroup = {
  id: number
  label: string
  hidden?: boolean
}

export type Menu = {
  groupId: number
  label: string
  icon: ReactNode
  link: string
}

export type MenuDetailNavigation = {
  id: number
  icon?: ReactNode
  badge?: ReactNode
  link: string
}

export type MappedMenuDetailsToNavigation = MenuDetailNavigation & {
  label: string
  order: number
  description?: string
  menuId: number
}

export const menuGroups: MenuGroup[] = [
  { id: menu.DASHBOARD, label: 'ダッシュボード', hidden: true },
  { id: menu.INFORMATION, label: 'EL-Navi ニュース', hidden: true },
  { id: menu.KARTE, label: '従業員カルテ' },
  { id: menu.SICK, label: '不調者対応ナビ' },
  { id: menu.SHARE_NAVI, label: '情報共有ナビ' },
  { id: menu.SHARE_NAVI_MANAGER, label: '情報共有ナビ' },
  { id: menu.SHARE_NAVI_STAFF, label: '情報共有ナビ' },
  { id: menu.DETECTION, label: '早期発見ナビ' },
  { id: menu.HEALTH_CARE, label: '衛生管理ナビ' },
  { id: menu.HEALTH_CARE_SUPPORT, label: '衛生管理ナビ' },
  { id: menu.SETTING, label: '設定' },
]

// prettier-ignore
export const menuDetailNavigations: MenuDetailNavigation[] = [
  { id: menuDetail.DASHBOARD, link: '/home/dashboard', icon: <DashboardIcon /> },
  { id: menuDetail.PANELS_CHECKUP, link: '/health-care/medical-checkup', badge: <PlusLabel /> },
  { id: menuDetail.PANELS_OVERWORK, link: '/health-care/overwork', badge: <PlusLabel /> },
  { id: menuDetail.PANELS_STRESS_CHECK, link: '/health-care/stress-check', badge: <PlusLabel /> },
  { id: menuDetail.PANELS_WELLNESS_LITE, link: '/detection/wellness-lite' },
  { id: menuDetail.PANELS_WELLNESS_CHECK, link: '/navi/wellness-check' },
  { id: menuDetail.PANELS_REINSTATEMENT_CHECK, link: '/navi/reinstatement-check' },
  { id: menuDetail.NOTICE, link: '/notices' },
  { id: menuDetail.NOTICE, link: '/notices/:noticesId' },
  { id: menuDetail.KARTE, link: '/karte', icon: <ListIcon /> },
  { id: menuDetail.KARTE, link: '/karte/detail/:employeeId/*' },
  { id: menuDetail.TABS_MEMO, link: 'memo' },
  { id: menuDetail.TABS_INTERVIEW, link: 'interview' },
  { id: menuDetail.TABS_RECEIVED_REPORT, link: 'received-report' },
  { id: menuDetail.TABS_WELLNESS_LITE, link: 'wellness-lite' },
  { id: menuDetail.TABS_NAVI, link: 'navi' },
  { id: menuDetail.TABS_CHECKUP, link: 'checkup' },
  { id: menuDetail.TABS_OVERWORK, link: 'overwork' },
  { id: menuDetail.TABS_STRESS_CHECK, link: 'stress-check' },
  { id: menuDetail.NAVI, link: '/navi/top', icon: <PersonPinCircleIcon /> },
  { id: menuDetail.WELLNESS_CHECK, link: '/navi/wellness-check', icon: <HowToRegIcon /> },
  { id: menuDetail.WELLNESS_CHECK, link: '/navi/wellness-check/:surveyId/result' },
  { id: menuDetail.REINSTATEMENT_CHECK, link: '/navi/reinstatement-check', icon: <TransferWithinAStationIcon /> },
  { id: menuDetail.REINSTATEMENT_CHECK, link: '/navi/reinstatement-check/:surveyId/result' },
  { id: menuDetail.INTERVIEW, link: '/share-navi/interview', icon: <AssignmentInd /> },
  { id: menuDetail.INTERVIEW, link: '/share-navi/interview/:interviewId' },
  { id: menuDetail.INTERVIEW, link: '/share-navi/interview/:interviewId/edit' },
  { id: menuDetail.PERSONNEL_REPORT, link: '/share-navi/personnel-report', icon: <ChatIcon /> },
  { id: menuDetail.PERSONNEL_REPORT, link: '/share-navi/personnel-report/:personnelReportId' },
  { id: menuDetail.PERSONNEL_REPORT, link: '/share-navi/personnel-report/:personnelReportId/edit' },
  { id: menuDetail.RECEIVED_REPORT, link: '/share-navi/received-report', icon: <ChatIcon /> },
  { id: menuDetail.RECEIVED_REPORT, link: '/share-navi/received-report/personnel-report/:personnelReportId' },
  { id: menuDetail.WELLNESS_LITE, link: '/detection/wellness-lite', icon: <HowToRegIcon /> },
  { id: menuDetail.WELLNESS_LITE, link: '/detection/wellness-lite/:eventId' },
  { id: menuDetail.CHECKUP, link: '/health-care/medical-checkup', icon: <HealingIcon /> },
  { id: menuDetail.OVERWORK, link: '/health-care/overwork', icon: <WatchLaterOutlined /> },
  { id: menuDetail.STRESS_CHECK, link: '/health-care/stress-check', icon: <Equalizer /> },
  { id: menuDetail.SETTING, link: '/health-care/setting', icon: <SettingsApplicationsIcon /> },
  { id: menuDetail.SETTINGS_COMPANY, link: '/setting/company', icon: <BusinessIcon /> },
  { id: menuDetail.SETTINGS_BRANCH, link: '/setting/branch', icon: <PlaylistAddCheck /> },
  { id: menuDetail.SETTINGS_DEPARTMENT, link: '/setting/department', icon: <PlaylistAddCheck /> },
  { id: menuDetail.SETTINGS_EMPLOYEE, link: '/setting/employee', icon: <RecentActorsIcon /> },
  { id: menuDetail.SETTINGS_USER, link: '/setting/user', icon: <PersonAddAltIcon /> },
]
