import {
  CheckupFinalResultEmployeeParams,
  CheckupFinalResultEmployeesParams,
  CheckupResultClinicsParams,
  CheckupResultDocumentParams,
  CheckupResultEmployeesParams,
  CheckupResultSendMailConditions,
  CheckupResultSendMailParams,
  getCheckupFinalResultEmployee,
  getCheckupFinalResultEmployees,
  getCheckupResultClinics,
  getCheckupResultDocument,
  getCheckupResultEmployee,
  getCheckupResultEmployees,
  getCheckupResults,
  patchCheckupResult,
  patchCheckupFinalResultEmployee,
  patchCheckupResultEmployee,
  postCheckupResultEmployeesSendMail,
  patchResetAfterMeasure,
} from '../../../apis/healthCare/checkup'
import { AppDispatch } from '../../../store'
import { actions } from '../../../modules/healthCare/checkup'
import { enqueueErrorSnackbar, enqueueSnackbar } from '../../snackbars'
import {
  CheckupFinalResultEmployee,
  CheckupResult,
  CheckupResultEmployee,
} from '../../../interfaces/healthCare/checkup'
import { CheckupMenu } from '../../../pages/healthCare/checkup/hooks/useCheckupResultHistory'
import { checkResponseDataStatus, checkResponseStatus } from '../../api'
import { getUserId } from '../../../utils/userStorage'

export type CheckupResultEmployeeFormParams = Pick<
  CheckupResultEmployee,
  | 'medicalDecideId'
  | 'workingDecideId'
  | 'afterMeasureStatusId'
  | 'memo'
  | 'interviewId'
>

export const fetchCheckupResultEmployees =
  (fiscalYear: number | undefined, query?: CheckupResultEmployeesParams) =>
  (dispatch: AppDispatch): Promise<CheckupResultEmployee[] | void> => {
    if (fiscalYear === undefined) {
      dispatch(enqueueErrorSnackbar('内部エラーが発生しました(fiscalYear)'))
      return Promise.resolve()
    }
    dispatch(actions.setWaitingResultEmployees())
    return getCheckupResultEmployees(fiscalYear, query)
      .then(checkResponseStatus)
      .then((response) => {
        dispatch(
          actions.setResultEmployees({ results: response.data.checkupSurveys }),
        )
        dispatch(
          actions.setPaginationResults({ pagination: response.meta ?? null }),
        )
        return response.data.checkupSurveys
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const fetchCheckupResultEmployee =
  (resultId: string) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(actions.setWaitingResultEmployee())
    return getCheckupResultEmployee(resultId)
      .then(checkResponseDataStatus)
      .then((data) => {
        dispatch(actions.setResultEmployee(data))
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const sendCheckupResultEmployeeByIds =
  (fiscalYear: number | undefined, ids: number[], callback?: () => void) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (ids?.length < 1) {
      return Promise.resolve()
    }
    const params: CheckupResultSendMailParams = {
      targetType: 'ids',
      ids,
    }
    return dispatch(sendCheckupResultEmployees(fiscalYear, params, callback))
  }

export const sendCheckupResultEmployeeByConditions =
  (
    fiscalYear: number | undefined,
    conditions: CheckupResultSendMailConditions | undefined,
    callback?: () => void,
  ) =>
  (dispatch: AppDispatch): Promise<void> => {
    const params: CheckupResultSendMailParams = {
      targetType: 'conditions',
      conditions,
    }
    return dispatch(sendCheckupResultEmployees(fiscalYear, params, callback))
  }

const sendCheckupResultEmployees =
  (
    fiscalYear: number | undefined,
    params: CheckupResultSendMailParams,
    callback?: () => void,
  ) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (fiscalYear === undefined) {
      dispatch(enqueueErrorSnackbar('内部エラーが発生しました(fiscalYear)'))
      return Promise.resolve()
    }
    dispatch(actions.setWaitingSendMail())
    return postCheckupResultEmployeesSendMail(fiscalYear, params)
      .then(checkResponseDataStatus)
      .then(() => {
        dispatch(enqueueSnackbar('事後措置票メール送信を受け付けました'))
      })
      .then(() => {
        callback && callback()
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
      .finally(() => {
        dispatch(actions.resetWaitingSendMail())
      })
  }

export const fetchCheckupResults =
  (
    employeeId: number | undefined,
    employmentJudgmentId: string | number | undefined,
  ) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (!employeeId || employmentJudgmentId === undefined) {
      return Promise.resolve()
    }
    dispatch(actions.setWaitingResults())
    return getCheckupResults(employeeId, employmentJudgmentId)
      .then(checkResponseDataStatus)
      .then((data) => {
        dispatch(actions.setResults(data))
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
        dispatch(actions.resetWaitingResults())
      })
  }

export const updateCheckupResultEmployee =
  (
    employmentJudgmentId: string | undefined,
    params: CheckupResultEmployeeFormParams,
  ) =>
  (dispatch: AppDispatch): Promise<void> => {
    const userId = getUserId()
    if (!employmentJudgmentId || !userId) {
      return Promise.resolve()
    }
    const postParams = { ...params, userId }

    return patchCheckupResultEmployee(employmentJudgmentId, postParams)
      .then(checkResponseDataStatus)
      .then(() => {
        dispatch(enqueueSnackbar('判定の更新が完了しました'))
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const fetchCheckupResultDocument =
  (fiscalYearId: string, params?: CheckupResultDocumentParams) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (!fiscalYearId) {
      return Promise.resolve()
    }
    dispatch(actions.setWaitingResultDocument())
    return getCheckupResultDocument(fiscalYearId, params)
      .then(checkResponseDataStatus)
      .then((data) => {
        dispatch(actions.setResultDocument(data))
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const fetchCheckupResultClinics =
  (fiscalYearId: string, params?: CheckupResultClinicsParams) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (!fiscalYearId) {
      return Promise.resolve()
    }
    dispatch(actions.setWaitingResultClinics())
    return getCheckupResultClinics(fiscalYearId, params)
      .then(checkResponseDataStatus)
      .then((data) => {
        dispatch(actions.setResultClinics(data))
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
      .finally(() => {
        dispatch(actions.resetWaitingResultClinics())
      })
  }

export const fetchCheckupFinalResultEmployees =
  (
    fiscalYearId: number | undefined,
    params?: CheckupFinalResultEmployeesParams,
  ) =>
  (dispatch: AppDispatch): Promise<CheckupFinalResultEmployee[] | void> => {
    if (!fiscalYearId) {
      return Promise.resolve()
    }
    dispatch(actions.setWaitingResultEmployees())
    return getCheckupFinalResultEmployees(fiscalYearId, params)
      .then(checkResponseStatus)
      .then((response) => {
        dispatch(actions.setFinalResultEmployees(response.data))
        dispatch(
          actions.setPaginationFinalResultEmployees({
            pagination: response.meta ?? null,
          }),
        )
        return response.data.checkupSurveys
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const fetchCheckupFinalResultEmployee =
  (finalResultId: string) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(actions.setWaitingResultEmployee())
    return getCheckupFinalResultEmployee(finalResultId)
      .then(checkResponseDataStatus)
      .then((data) => {
        dispatch(actions.setFinalResultEmployee(data))
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const changeFinalResultEmployee =
  (key: keyof CheckupFinalResultEmployeeParams, value: string | number) =>
  (dispatch: AppDispatch): { type: string } => {
    return dispatch(actions.changeFinalResultEmployeeEdit({ key, value }))
  }

export const updateCheckupResult =
  (params: CheckupResult) =>
  (dispatch: AppDispatch): Promise<void> => {
    return patchCheckupResult({ checkupResult: params })
      .then(checkResponseDataStatus)
      .then(() => {
        dispatch(
          fetchCheckupResults(params.employeeId, params.employmentJudgmentId),
        )
        dispatch(enqueueSnackbar('健診結果の更新が完了しました'))
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const updateCheckupFinalResultEmployee =
  (
    finalResultId: string | undefined,
    params: CheckupFinalResultEmployeeParams | null,
  ) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (!finalResultId || params === null) {
      return Promise.resolve()
    } else {
      const finalDecidedUserId = getUserId()
      if (finalDecidedUserId) {
        params = { ...params, finalDecidedUserId }
      }
    }
    return patchCheckupFinalResultEmployee(finalResultId, params)
      .then(checkResponseDataStatus)
      .then(() => {
        dispatch(enqueueSnackbar('判定の更新が完了しました'))
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const resetCheckupFinalResultEmployees =
  () =>
  (dispatch: AppDispatch): { type: string } => {
    return dispatch(actions.resetFinalResultEmployees())
  }

export const resetCheckupFinalResultEmployee =
  () =>
  (dispatch: AppDispatch): { type: string } => {
    return dispatch(actions.resetFinalResultEmployee())
  }

export const resetAfterMeasure =
  (finalResultId: string, callback?: () => void) =>
  (dispatch: AppDispatch): Promise<void> => {
    return patchResetAfterMeasure(finalResultId)
      .then(checkResponseDataStatus)
      .then(() => {
        dispatch(enqueueSnackbar('受診報告を取り下げました'))
        callback && callback()
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

const MAX_SELECTED = 3

/**
 * 選択健診履歴の取得
 *
 * 初期表示する健診結果は選択した健診結果のみ
 *
 * @param employmentJudgmentId - 選択された従業員判定ID
 * @param results - 表示するメニューオブジェクト配列
 */
export const getInitialMenus = (
  employmentJudgmentId: string,
  results: CheckupResult[] | null,
): CheckupMenu[] => {
  const selectedIdNumber = Number.parseInt(employmentJudgmentId)
  if (!selectedIdNumber || results === null) {
    return []
  }

  return results.map((result) => ({
    id: result.employmentJudgmentId,
    consultedAt: result.consultedAt,
    disabled: selectedIdNumber === result.employmentJudgmentId,
    checked: selectedIdNumber === result.employmentJudgmentId,
    decideTarget: selectedIdNumber === result.employmentJudgmentId,
  }))
}

export const changeResultMenus = (
  checkedId: number,
  menus: CheckupMenu[],
): CheckupMenu[] => {
  const checkedMenus = menus.map((menu) => {
    return {
      ...menu,
      checked: menu.id === checkedId ? !menu.checked : menu.checked,
    }
  })
  const selectedCount = checkedMenus.filter((menu) => menu.checked).length

  return checkedMenus.map((menu) => {
    /*
     * 選択肢が3つ以上の場合、かつチェックされていない場合には 非活性 にする。
     * または
     * 判定入力対象は常に 非活性 にする。
     */
    const disabled =
      (MAX_SELECTED <= selectedCount && !menu.checked) || menu.decideTarget

    return { ...menu, disabled }
  })
}
