import { Fragment, useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  ContainerProps,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import { Lock, RecentActors } from '@mui/icons-material'
import { setUserAccount, setUserToken } from '../../utils/userStorage'
import { isLoginEmail } from '../../utils/validations'
import { ApiCompany, ApiLoginLock, LoginResponse } from '../../apis/session'
import { Copyright } from './Copyright'
import { OtpDialog } from '../auth/OtpDialog'
import { useAppDispatch } from '../../hooks'
import { getUserTopPage } from '../../services/auth/userRole'
import { login } from '../../services/auth/user'
import { TopTitleLogo } from '../../components/Logo'

export const Login = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [isOpenBackdrop, setIsOpenBackdrop] = useState(false)
  const [email, setEmail] = useState('')
  const [validPassword, setValidPassword] = useState({
    value: '',
    error: false,
    message: '8〜72文字で「¥」「\\」「[]」以外の英数・記号',
    inputRef: useRef<HTMLInputElement>(null),
    showPassword: false,
  })
  const [vEmail, setVEmail] = useState({ disp: false, message: '' })
  const [disabled, setDisabled] = useState(false)
  const [loginLocked, setLoginLocked] = useState(false)
  const [isPasswordFaile, setIsPasswordFaile] = useState(false)
  const [otpData, setOtpData] = useState({
    userId: 0,
    otpAuth: 0,
    otpAuthName: '',
    lastOtpDate: '',
    otpSecret: '',
  })
  const [isOpenOtp, setIsOpenOtp] = useState(false)
  const dispatch = useAppDispatch()

  const fetchCompany = useCallback(() => {
    ApiCompany().then((response) => {
      try {
        if (response.status === 'error') {
          return enqueueSnackbar(response.message, {
            variant: 'error',
            key: 'login',
          })
        }
      } catch (e) {
        setDisabled(true)
        return enqueueSnackbar('このURLからはログインできません', {
          variant: 'error',
          key: 'login',
        })
      }
    })
  }, [enqueueSnackbar])

  const fetchLoginLock = useCallback(() => {
    ApiLoginLock({ email: email }).then((response) => {
      try {
        if (response.status === 'success') {
          sessionStorage.setItem('passwordFaileCount', '0')
          setDisabled(true)
          return setLoginLocked(true)
        }
        if (response.status === 'error') {
          setDisabled(true)
          return enqueueSnackbar(response.message, {
            variant: 'error',
            key: 'resetPassword',
          })
        }
      } catch (e) {
        setDisabled(true)
        return enqueueSnackbar('このURLからはログインできません', {
          variant: 'error',
          key: 'resetPassword',
        })
      }
    })
  }, [email, enqueueSnackbar])

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentStr = event.currentTarget.value
    setEmail(currentStr)
  }

  const handleValidateEmail = () => {
    if (email && !isLoginEmail(email)) {
      setVEmail({ disp: true, message: 'Emailを正しく入力してください' })
    } else {
      setVEmail({ disp: false, message: '' })
    }
  }

  const handleValidatePassword = () => {
    if (validPassword.inputRef.current === null) {
      return
    }
    // Reactで、min,max,patternの3つをチェックする
    if (!validPassword.inputRef.current.validity.valid) {
      setValidPassword({
        ...validPassword,
        error: true,
        message:
          '8〜72文字の「¥」「\\」「[]」以外の英数・記号を正しく入力してください',
      })
    } else {
      setValidPassword({ ...validPassword, error: false, message: '' })
    }
  }

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Reactで、min,max,patternの3つをチェックする
    if (validPassword.inputRef.current) {
      if (!validPassword.inputRef.current.validity.valid) {
        setValidPassword({
          ...validPassword,
          value: event.target.value,
          error: true,
          message:
            '8〜72文字の「¥」「\\」「[]」以外の英数・記号を正しく入力してください',
        })
      } else {
        setValidPassword({
          ...validPassword,
          value: event.target.value,
          error: false,
          message: '',
        })
      }
    }
  }

  const handleForgotPassword = () => {
    navigate('/forgot-password')
  }

  const handleLoginSubmit = () => {
    if (validPassword.inputRef.current === null) {
      return
    }
    if (isLoginEmail(email) && validPassword.inputRef.current.validity.valid) {
      loginSubmit()
    } else {
      return enqueueSnackbar(
        'メールアドレスとパスワードを正しく設定して下さい',
        { variant: 'error', key: 'login' },
      )
    }
  }

  const loginSubmit = useCallback(() => {
    setIsOpenBackdrop(true)

    const onSuccess = async (response: LoginResponse) => {
      setLoginLocked(response.isLoginLocked)
      sessionStorage.removeItem('passwordFaileCount')
      setIsOpenBackdrop(false)
      if (response.isLoginLocked) {
        setDisabled(true)
      } else {
        setUserAccount(response.data)
        setUserToken(response.data)
        fetchCompany()
        if (response.data.loginCount == 1) {
          // 初回ログイン時はパスワード変更させる
          navigate('/reset-password/' + response.data.resetPasswordToken)
        } else {
          if (response.data.otpAuth == 0) {
            // ログイン完了 トップ画面へ
            const topPage = await dispatch(
              getUserTopPage(response.data.currentUserRoleId),
            )
            navigate(topPage)
          } else {
            // ログイン完了 ２段階認証へ
            setOtpData({
              ...otpData,
              userId: response.data.id,
              otpAuth: response.data.otpAuth,
              otpAuthName: response.data.otpAuthName,
              lastOtpDate: response.data.lastOtpDate ?? '',
              otpSecret: response.data.otpSecret ?? '',
            })
            setIsOpenOtp(true)
          }
        }
      }
    }

    const onError = (response: LoginResponse) => {
      setLoginLocked(response.isLoginLocked)
      setIsPasswordFaile(response.isPasswordFaile)
      // パスワード間違いチェック
      if (response.isPasswordFaile === true) {
        if (!response.isLoginLocked) {
          let faileCount = sessionStorage.getItem('passwordFaileCount') ?? '0'
          faileCount = String(Number(faileCount) + 1)
          sessionStorage.setItem('passwordFaileCount', faileCount)
          if (Number(faileCount) >= 3) {
            // パスワードロック処理
            fetchLoginLock()
          }
        }
      }
      setIsOpenBackdrop(false)
      return enqueueSnackbar(response.message, {
        variant: 'error',
        key: 'login',
      })
    }

    dispatch(
      login(
        {
          email: email,
          password: validPassword.value,
        },
        { onSuccess, onError },
      ),
    )
  }, [
    dispatch,
    email,
    enqueueSnackbar,
    fetchLoginLock,
    navigate,
    otpData,
    validPassword.value,
    fetchCompany,
  ])

  const handleSuccessOtp = async () => {
    // ログイン完了
    const topPage = await dispatch(getUserTopPage())
    navigate(topPage)
    setIsOpenOtp(false)
  }

  const handleCancelOtp = () => {
    setIsOpenOtp(false)
  }

  return (
    <Fragment>
      <BackdropStyled open={isOpenBackdrop}>
        <CircularProgress color="inherit" />
      </BackdropStyled>
      <MainContainer component="main" maxWidth="xs">
        <Typography component="h1" variant="h5" sx={{ textAlign: 'center' }}>
          アカウントにログイン
        </Typography>
        <HelperTextStyled>
          <TitleLogo />
          <TextField
            id="emailtext"
            required
            variant="outlined"
            label="メールアドレス"
            disabled={disabled}
            value={email}
            onChange={handleChangeEmail}
            onBlur={handleValidateEmail}
            margin="normal"
            name="email"
            autoComplete="email"
            autoFocus
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <RecentActorsIconStyled />
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 50,
            }}
            error={vEmail.disp}
            helperText={vEmail.message}
          />
          <TextField
            id="passtext"
            required
            variant="outlined"
            label="パスワード"
            type="password"
            disabled={disabled}
            value={validPassword.value}
            onChange={handleChangePassword}
            onBlur={handleValidatePassword}
            margin="normal"
            fullWidth
            name="password"
            autoComplete="off"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIconStyled />
                </InputAdornment>
              ),
            }}
            inputProps={{
              minLength: 8,
              maxLength: 72,
              pattern: '^[a-zA-Z0-9_!@#$%^&*()+-={};:?,.]+$',
            }}
            inputRef={validPassword.inputRef}
            error={validPassword.error}
            helperText={validPassword.message}
          />

          {loginLocked && (
            <Fragment>
              <LoginButtonStyled
                variant="contained"
                fullWidth
                color="secondary"
                size="large"
                disabled
                onClick={handleLoginSubmit}
              >
                ロックされています
              </LoginButtonStyled>
              <Box display="flex">
                <Box>
                  <AvatarRedStyled>
                    <Lock />
                  </AvatarRedStyled>
                </Box>
                <Box p={4}>
                  <LockMessageStyled variant="caption">
                    60分以上たってから再度ログインをお試し下さい
                  </LockMessageStyled>
                </Box>
              </Box>
            </Fragment>
          )}
          {!loginLocked && (
            <LoginButtonStyled
              variant="contained"
              fullWidth
              color="primary"
              size="large"
              disabled={disabled}
              onClick={handleLoginSubmit}
            >
              ログイン
            </LoginButtonStyled>
          )}
          {!loginLocked && isPasswordFaile && (
            <Box display="flex">
              <Box>
                <AvatarRedStyled>
                  <Lock />
                </AvatarRedStyled>
              </Box>
              <Box p={2}>
                <LockMessageStyled variant="caption">
                  <b>{sessionStorage.getItem('passwordFaileCount')}/3</b>
                  <br />
                  3回パスワードを間違えるとロックされます
                </LockMessageStyled>
              </Box>
            </Box>
          )}
        </HelperTextStyled>

        {loginLocked ? (
          <HelperTextStyled>
            <a href="mailto:system-c@elixia.co.jp">サポートに連絡</a>
          </HelperTextStyled>
        ) : (
          <HelperTextStyled>
            <Button color="primary" onClick={handleForgotPassword}>
              パスワードを忘れた場合
            </Button>
          </HelperTextStyled>
        )}

        <Box mt={8}>
          <Copyright />
        </Box>
      </MainContainer>

      <OtpDialog
        isOpen={isOpenOtp}
        isSelect={otpData.lastOtpDate ? false : true}
        userId={otpData.userId}
        email={email}
        otpAuth={otpData.otpAuth}
        otpAuthName={otpData.otpAuthName}
        lastOtpDate={otpData.lastOtpDate}
        otpSecret={otpData.otpSecret}
        onSuccess={handleSuccessOtp}
        onCancel={handleCancelOtp}
      />
    </Fragment>
  )
}

const MainContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(20),
}))

const HelperTextStyled = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const LoginButtonStyled = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
}))

const LockIconStyled = styled(Lock)(({ theme }) => ({
  fontSize: 20,
  marginLeft: theme.spacing(1),
  opacity: 0.8,
}))

const RecentActorsIconStyled = styled(RecentActors)(({ theme }) => ({
  fontSize: 20,
  marginLeft: theme.spacing(1),
  opacity: 0.8,
}))

const BackdropStyled = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}))

const AvatarRedStyled = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(2),
  color: '#f14c4c',
  backgroundColor: '#e0e0e0',
}))

const LockMessageStyled = styled(Typography)(() => ({
  color: '#f14c4c',
  flexGrow: 1,
}))

const TitleLogo = styled(TopTitleLogo)(() => ({
  width: 340,
  height: 100,
}))
